<template>
  <div class="mt-2">
    <CRow>
      <CCol col="12" xl="12">
        <dataTableExtended
          class="align-center-row-datatable"
          hover
          sorter
          small
          details
          column-filter
          :items="formatedItems"
          :fields="fields"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="tableText.itemsPerPage"
          pagination
        >
          <template #Nro="{ index }">
            <td class="text-center">
              {{index+1}}
            </td>
          </template>
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Detalle="{item}">
            <td class="text-center">
              <CButton
                color="edit"
                square
                size="sm"
                class="mr-2"
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.bl'),
                  placement: 'top-start'
                  }"
                @click="toggleContainerEdit(item)"
            >
              <CIcon name="pencil" />
            </CButton>

            <CButton
              square
              size="sm"
              color="watch"
              v-c-tooltip="{
                  content: $t('label.see')+' '+$t('label.document'),
                  placement: 'top-start'
              }"
              @click="viewDocument(item)"
            >
              <CIcon name="eye" />
            </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <ModalBl
      :modal.sync="ModalBl"
      :ContainerItem="ContainerItem"
      :items="Object.keys(this.Bl).length != 0 ? [this.Bl] : []"
      :title="titleModal"
      :isEdit="isEdit"
      @close="closeModal"
      @Update-list="Update"
    />
    <ModalBlDocument
      :modal.sync="ModalBlDocument"
      :title="titleDocumentsModal"
      :documentList="documentList"
      @close="closeModal"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import ModalBl from './modal-bl/index';
import ModalBlDocument from '@/pages/yard-management/container/bl/modal-bl-document';
import { DateFormater, removeTags, trimRemoveSpecialCharacters} from '@/_helpers/funciones';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

//DATA-------------------------
function data() {
  return {
    ModalBl: false,
    ModalBlDocument: false,
    ContainerItem:{},
    titleModal: '',
    titleDocumentsModal: '',
    isEdit: false,
    documentList: [],
  }
}
//methods

async function getYardCargo() {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
          let yardCargoReception = '', yardCargoDispatch = ''; 

          yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
          yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

          this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
        } 
        else{
          this.$store.state.yardManagement.dataContainer = List[0];
        }
        
        if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
          this.$store.state.yardManagement.globalSelectContainer = 
            this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
              return Select.CargoId == this.CargoId ? List[0] : Select;
            });
        }
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function toggleContainerAdd() {
  this.ModalBl = true;

  this.isEdit = false;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.bl');
}

function toggleContainerEdit(item) {
  this.ContainerItem = item;
  this.isEdit = true;
  this.titleModal = `${this.$t('label.edit')} ${this.$t('label.bl')}: ${this.ContainerItem.BlNro}`;
  this.ModalBl = true;
}

function viewDocument(item){
  this.ModalBlDocument = true;
  let title = item.BlNro;
  this.documentList = item.DocumentJson;
  this.titleDocumentsModal = this.$t('label.blDocuments')+': '+title;
}

async function Update() {
  // this.$store.state.yardManagement.loading = true;
  // this.isEdit = false;
  // await this.getYardCargo();
}

function closeModal(){
  this.ModalBl = false;
  this.ModalBlDocument = false;
  this.documentList = [];
  this.ContainerItem = {};
  this.titleModal = '';
  this.titleDocumentsModal = '';
  this.isEdit = false;
}

//computed
function formatedItems() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let list = Object.keys(this.Bl).length != 0 ? [this.Bl] : [];
  return list.map((item) => {
    return {
      ...item,
      ComputedBookingNro: item.BookingNro ? item.BookingNro : 'N/A',
      ComputedCustomOperation:  item[`CustomOperationDs${_lang}`] ? item[`CustomOperationDs${_lang}`] : 'N/A',
      ComputedCustomBrokerName: item.CustomBrokerName ?? 'N/A',
      ComputedConsigneeName: item.ConsigneeName ?? 'N/A',
      ComputedDua: item.Dua ?  item.Dua : 'N/A',
      ComputedProcedence: item.Procedence ? item.Procedence : 'N/A',
      ComputedReceptionBpAct: item.ReceptionBpAct ? item.ReceptionBpAct : 'N/A',
      ComputedQuantity: item.Quantity ? item.Quantity : 0,
      ComputedWeigth: item?.Weigth ? formatMilDecimal(item?.Weigth?.toFixed(2)) : '0,00',
      ComputedVolumen: item?.Volumen ? formatMilDecimal(item?.Volumen?.toFixed(2)) : '0,00',
      ComputedDescriptionOfGoods: item?.DescriptionOfGoods ? trimRemoveSpecialCharacters(removeTags(item.DescriptionOfGoods)) : 'N/A',
      TransaLogin: item.TransaLogin ?? 'N/A',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate): 'N/A',
    };
  });
}

function fields(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return [
    { key: "Detalle", label: "",_style:'width:10%;min-width:90px;', _classes:'center-cell',filter: false,},
    { key: 'Nro', label: '#',_style: 'min-width: 60px; text-align:left;', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'BlNro',label: this.$t('label.bl'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
    { key: 'ComputedConsigneeName', label: this.$t('label.consignee'), _style:'min-width:150px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'ComputedCustomBrokerName', label: this.$t('label.customBroker'),  _style:'min-width:170px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
    { key: 'ComputedCustomOperation', label: this.$t('label.customsRegime'),  _style:'min-width:220px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
    { key: 'ComputedBookingNro', label: this.$t('label.nBooking'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
    { key: 'ComputedDescriptionOfGoods', label: this.$t('label.inventoryContains'), _style:'min-width:220px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: `BlStatus${_lang}`, label: this.$t('label.blStatus'), _style:'min-width:140px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'ComputedDua', label: this.$t('label.nDua'), _style:'min-width:140px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'ComputedReceptionBpAct', label: this.$t('label.bolipuertoReceptionRecordNro'), _style:'min-width:300px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'ComputedProcedence', label: this.$t('label.Origin'),  _style:'min-width:150px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
    { key: 'ComputedWeigth', label: this.$t('label.weight')+' '+('(KGM)'), _style:'min-width: 140px;', _classes: 'text-uppercase text-center'},
    { key: 'ComputedVolumen', label: this.$t('label.volume')+' '+('(M³)'), _style:'min-width: 140px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedQuantity', label: this.$t('label.quantity'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
  ];
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
}

export default {
  name: 'basicData-index',
  components: {
    ModalBl,
    ModalBlDocument,
  },
  data,
  mixins: [GeneralMixin],
  props: {
    Bl: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    getYardCargo,
    toggleContainerAdd,
    toggleContainerEdit,
    viewDocument,
    Update,
    closeModal,
    removeTags,
    trimRemoveSpecialCharacters
  },
  computed: {
    fields,
    formatedItems,
    ValidateTpCargoStatus,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
      YardId: state => state.yardManagement.yardData.YardId,
      dataContainer: state => state.yardManagement.dataContainer,
      GeneralList: state => state.yardManagement.GeneralList,
      yardCollapse: state => state.yardManagement.yardCollapse,
      isSelect: state => state.yardManagement.isSelect,
      tabIndex: state => state.yardManagement.ContainerTabIndex,
      FgPositioning: state => state.yardManagement.FgPositioning,
    })
  },
};
</script>