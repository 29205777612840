<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.BlQuery')}}  </b>
      </CCardHeader>
      <div class="mt-3">
        <CRow class="justify-content-center m-0">
          <CCol sm="12" lg="5">
            <div class="form-group form-row" rol="group">
              <label class="col-form-label-sm col-sm-12 col-lg-3 text-right Label">{{$t('label.visitItineraty')}}</label>
              <div class="col-sm-12 col-lg-9">
                <v-select 
                  id="v-select-small"
                  style="font-size: 11px;"
                  :placeholder="$t('label.select')"
                  :options="VisitOptions"
                  :reduce="option => option.value"
                  v-model="VisitIdOrItineraryId"
                  :disabled="this.VisitList.length === 0"
                  @input="onChangeVisitId"
                >
                  <template #no-options="{ }">
                    {{$t('label.noResultsFound')}}
                  </template>
                </v-select>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="4">
            <div class="form-group form-row" rol="group">
              <label class="col-form-label-sm col-sm-12 col-lg-3 text-right Label">{{$t('label.bl')}}</label>
              <div class="col-sm-12 col-lg-9">
                <div v-show="!VisitIdOrItineraryId" id="BlSearch" class="form-group form-row m-0" style="flex-wrap: nowrap;">
                  <v-select 
                    id="v-select-small"
                    ref="BlSearch"
                    class="form-bl-Search"
                    style="font-size: 11px;"
                    :options="BlOption"
                    :reduce="option => option.value"
                    v-model.trim="BlCargoId"
                    :clearSearchOnSelect="false"
                    :clearable="false"
                    :filterable="false"
                    :dropdownShouldOpen="dropdownShouldOpen"
                    @search="(search, loading) => BlSearch = search"
                    @input="(SelectBl=BlList?.find(item => item.BlCargoId == $event), $refs.BlSearch.search = SelectBl?.BlNro ?? '', BlSearch = SelectBl?.BlNro ?? '')"
                  > 
                    <template #no-options="{}">
                      {{$t('label.noResultsFound')}}
                    </template>
                    <template #option="{ Json, label, value }">
                      {{ label }}
                      <br v-if="value"/>
                      <cite v-if="value&&Json?.VesselName">{{ Json?.VesselName }} - {{ Json?.VoyageArrival }} </cite>
                    </template>
                  </v-select>
                  <div class="input-group-append">
                    <div class="d-flex align-items-start">
                      <CButton
                        size="sm"
                        class="btn-light-watch mr-2"
                        style="padding: 0.15rem 0.4rem;"
                        @click="getBlCargoList"
                      >
                        <CIcon name="cil-search"/>
                      </CButton>

                      <CButton
                        size="sm"
                        class="btn-add"
                        style="padding: 0.15rem 0.4rem;"
                        @click="toggleBlAdd"
                      >
                        <CIcon name="cil-plus"/>
                      </CButton>
                    </div>
                  </div>
                </div>
                <CSelect
                  v-if="VisitIdOrItineraryId"
                  size="sm"
                  :options="BlOption"
                  :v-model.trim="BlCargoId"
                  :horizontal="{input:'col-sm-12 m-0'}"
                  @change="onChangeBlCargoId($event.target.value)"
                />
              </div>
            </div>
          </CCol>
        </CRow>
        <CCol v-if="Object.keys(SelectBl).length != 0" sm="12" class="mb-2">
          <BlInfo 
            :Bl="SelectBl"
          />
        </CCol>
      </div>
    </CCard>
    <ModalBl
      :modal.sync="ModalBl"
      :items="[]"
      :title="`${$t('label.nuevo')} ${$t('label.bl')}`"
      @close="ModalBl = false"
    />
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import BlInfo from './bl-info'
import ModalBl from './taps/basicData/modal-bl/index';
import { mapState } from 'vuex';
import ENUM from '@/_store/enum';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    ModalBl: false,
    VisitIdOrItineraryId: '',
    VisitList: [],
    BlCargoId: '',
    BlSearch: '',
    BlList: [],
    SelectBl: {},

    alertModal: false,
    yardSelected: '',
    dataYard: [],
    setDataYard: [],
  }
}

//methods
function getYardVisitList() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("YardBlCargoVisitItinerary-by-CompanyBranchId", { CompanyBranchId: this.branch.CompanyBranchId })
  .then(response => {
    this.VisitList = response.data.data ? response.data.data : [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

async function getBlCargoList() {
  if (this.VisitIdOrItineraryId || this.BlSearch.length != 0) {
    this.$store.state.yardManagement.loading = true;
    this.BlList = [];
    let Vessel = {};
    if (this.VisitIdOrItineraryId) {
      Vessel = this.VisitOptions.find(e => e.value == this.VisitIdOrItineraryId) ?? {};
    }
    await this.$http.get("BlCargo-list", { BlCargoJson: { BlCargoJson: [{VisitId: Vessel?.VisitId ?? '', ItineraryId: Vessel?.ItineraryId ?? '', SearchKey: this.BlSearch}] } })
    .then(response => {
      this.BlList = response?.data?.data ?? [];
      if (this.BlCargoId && !this.BlList.some(item => item.BlCargoId == this.BlCargoId)) {
        this.BlList.unshift(this.SelectBl);
      }
      if (!this.VisitIdOrItineraryId) {
        this.$refs.BlSearch.open = true;
      }
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
  }
}

function onChangeVisitId() {
  this.$refs.BlSearch.search = '';
  this.BlCargoId = '';
  this.BlSearch = '';
  this.BlList = [];
  this.SelectBl = {};
  this.getBlCargoList();
}

function onChangeBlCargoId(id) {
  this.BlCargoId = id;
  this.SelectBl = {};
  if (id) {
    this.SelectBl = this.BlList?.find(item => item.BlCargoId == id);
  }
}

function toggleBlAdd() {
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.bl');
  this.ModalBl = true;
}

function dropdownShouldOpen(VueSelect) {
  return VueSelect.open;
}

//Computed
function BlOption() {
  let chart = [];
  if (this.VisitIdOrItineraryId) {
    chart.push({
      value: '', 
      label: this.$t('label.select'),
    });
  }
  this.BlList.map(list => {
    chart.push({
      value: list.BlCargoId,
      label: list.BlNro,
      Json: {...list }
    });
  });
  return chart;
}

function VisitOptions(){
  let _lang = this.$i18n.locale;

  if(this.VisitList.length == 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var visit = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.VisitList.map(function(e){
      visit.push({
        ...e,
        value: e.VisitId ?? e.ItineraryId, 
        label: `${e.VesselName ?? 'N/A'}, ${e.LabelArrival ?? ''} ${e.Arrival ? DateFormater.formatDateTimeWithSlash(e.Arrival) : 'N/A'} - ${e.LabelDeparture ?? ''} ${e.Departure ? DateFormater.formatDateTimeWithSlash(e.Departure) : 'N/A'}`,
      })
    })
    return visit;
  }
}

function YardsOptions() {
  if(this.dataYard?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }] 

    this.dataYard?.map(function(e){
      chart.push({
        value: e.YardId,
        label: e.YardName,
      })
    })
    
    return chart;
  }
  
}

function validateYardCargoStatus() {
  return process.env.VUE_APP_YARD_CARGO_STATUS_SHIP_SIDE === this.dataContainer?.HeaderJson?.[0]?.YardCargoStatus;
}


export default {
  name: 'index',
  components: {
    CustomTabs,
    CustomTab,
    BlInfo,
    ModalBl,
   },
  data,
  mounted(){
    this.$store.state.yardManagement.yardCollapse == 0;
    window.addEventListener('click', (e) => {
      if (!this.VisitIdOrItineraryId && document.getElementById('BlSearch') && !document.getElementById('BlSearch').contains(e.target)) {
        this.$refs.BlSearch.open = false;
      }
    })
    document.getElementById('BlSearch').addEventListener('keydown', (e) => {
      if (e.key === 'Enter' && !this.VisitIdOrItineraryId) {
        this.getBlCargoList();
      }
    })
    this.getYardVisitList();
  },
  beforeDestroy () {
    this.$store.state.yardManagement.yardCollapse = 0;
    this.$store.state.yardManagement.dataContainer = {};
    this.$store.state.containerSearch.dataContainer = {};
    this.$store.state.yardManagement.loading= false;
    this.$store.state.yardManagement.yardData = {};
  },
  mixins: [GeneralMixin],
  methods: {
    getBlCargoList,
    toggleBlAdd,
    dropdownShouldOpen,
    onChangeVisitId,
    onChangeBlCargoId,
    getYardVisitList,
  },
  computed: {
    YardsOptions,
    VisitOptions,
    BlOption,
    validateYardCargoStatus,
    ...mapState({
      branch: state => state.auth.branch,
      config: state => state.auth.config,
      loading: state => state.yardManagement.loading,
      dataContainer: state => state.containerSearch.dataContainer,
      successReception: state => state.containerSearch.successReception,
      yardCollapse: state => state.yardManagement.yardCollapse,
    })
  },
  watch: {
    
  },
};
</script>
<style lang="scss">
@import '@/pages/yard-management/css/style';

.form-bl-Search{
  width: 100%;
  color: #333;
  .vs__search {
    opacity: 1 !important;
  }
  .vs__actions {
    display: none !important;
  }
  .vs__dropdown-toggle {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
</style>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
</style>