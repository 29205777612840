<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12">
      <CRow>
        <CCol sm="12" lg="3">
          <CSelect
            size="sm"
            :label="$t('label.packaging')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :options="PackagingOptions"
            addLabelClasses="required text-right"
            v-model="vForm.serialization.PackagingId.$model"
            :is-valid="hasError(vForm.serialization.PackagingId)"
            :invalid-feedback="errorMessage(vForm.serialization.PackagingId)" 
          />
        </CCol>
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.totalQuantity')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="QuantityMeasure" 
                class="form-control"
                v-model="Packaging.Quantity"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.weight')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="Packaging.Weight"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.volume')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="Packaging.Volumen"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCol>


        
    
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedSerializationList"
        :fields="serializationfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CButton 
              v-if="!ValidatePackagingContainer(item.PackagingId)"
              color="edit"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.packaging'),
                placement: 'top-end'
              }"
              @click="EditPackaging(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              v-if="!ValidatePackagingContainer(item.PackagingId)"
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.packaging'),
                placement: 'top-end',
              }"
              @click="DeletePackaging(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
      <div class="text-invalid-feedback text-center" v-if="vForm.PackagingList.$error">
        {{ $t('validation.RequiredDetail') }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    
    CleanInformation: false,
    isSubmit: false,
    Packaging: {
      Weight: 0,
      Volumen: 0,
      Quantity: 0,
      SerializedQuantity: 0,
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
  };
}

//methods
function getService() {
  this.$store.state.yardManagement.loading = true;
  let peticiones = [
    this.$http.ejecutar("GET", "Packaging-list", { Filter: 'ACTIVO' }),
  ];
  Promise.all(peticiones)
    .then((responses) => {
      this.PackagingSelectList = responses[0].data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

async function SubmitPackaging() {
  try {
    this.isSubmit = true;
    this.vForm.Packaging.$touch();
    if (this.vForm.Packaging.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    if (this.form.Packaging.Id=='' && this.form.PackagingList.some(item => item.PackagingId == this.form.Packaging.PackagingId && item.Status == 1)) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }

    let PackagingData = this.PackagingSelectList.find(item => item.PackagingId == this.form.Packaging.PackagingId);
    if (this.form.Packaging.Id=='') {
      this.form.PackagingList.push({
        IdX: this.form.PackagingList.length > 0 ? this.form.PackagingList[this.form.PackagingList.length - 1].IdX + 1 : 1,
        PackagingId: this.form.Packaging.FgPackaging ? this.form.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
        HeadingId: this.form.Packaging.HeadingId ?? '',
        HeadingName: this.form.Packaging.HeadingName ?? '',
        CommodityId: this.form.Packaging.CommodityId ?? '',
        Serial: this.form.Packaging.Serial ?? '',
        DescriptionOfGoods: this.form.Packaging.DescriptionOfGoods ?? '',  
        Length: NumberFormater.setNum(this.form.Packaging.Length),
        Width: NumberFormater.setNum(this.form.Packaging.Width),
        Height: NumberFormater.setNum(this.form.Packaging.Height),
        SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
        Weight: this.form.Packaging.Weight,
        Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
        Volumen: this.Meters.Volumen,
        PackagingNameEn: PackagingData?.PackagingNameEn ?? '',
        PackagingNameEs: PackagingData?.PackagingNameEs ?? '',
        FgExternPackaging: this.FgExternPackaging,
        Status: 1,
      });
    }else{
      this.form.PackagingList = this.form.PackagingList.map(item => {
        if (item.IdX == this.form.Packaging.Id) {
          return {
            IdX: item.IdX,
            VisitBillOfLadingPackagingId: item.VisitBillOfLadingPackagingId,
            HeadingId: this.form.Packaging.HeadingId ?? '',
            HeadingName: this.form.Packaging.HeadingName ?? '',
            CommodityId: this.form.Packaging.CommodityId ?? '',
            Serial: this.form.Packaging.Serial ?? '',
            DescriptionOfGoods: this.form.Packaging.DescriptionOfGoods ?? '',
            Length: NumberFormater.setNum(this.form.Packaging.Length),
            Width: NumberFormater.setNum(this.form.Packaging.Width),
            Height: NumberFormater.setNum(this.form.Packaging.Height),
            SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
            PackagingId: this.form.Packaging.FgPackaging ? this.form.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
            Weight: NumberFormater.setNum(this.form.Packaging.Weight), 
            Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
            Volumen: NumberFormater.setNum(this.Meters.Volumen),
            PackagingNameEn: PackagingData?.PackagingNameEn ?? '',
            PackagingNameEs: PackagingData?.PackagingNameEs ?? '',
            FgExternPackaging: this.FgExternPackaging,
            Status: 1,
          }
        }else{
          return item;
        }
      });
    }
    await this.Clear(false);
    this.isSubmit = false;
    this.vForm.Packaging.$reset();
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

function EditPackaging(item) {
  this.form.Packaging = {
    Id: item.IdX,
    PackagingId: item.PackagingId ?? '',
    PackagingNameEn: item.PackagingNameEn,
    PackagingNameEs: item.PackagingNameEs,
    HeadingId: item.HeadingId ?? '',
    HeadingName: item.HeadingName ?? '',
    CommodityId: item.CommodityId ?? '',
    Serial: item.Serial ?? '',
    DescriptionOfGoods: item.DescriptionOfGoods ?? '',
    Length: item.Length ?? 0,
    Width: item.Width ?? 0,
    Height: item.Height ?? 0,
    Weight: NumberFormater.setNum(item.Weight), 
    Quantity: item.Quantity,
  };
  this.FgExternPackaging = item.FgExternPackaging ? true : false;
  this.form.Packaging.FgPackaging = item.PackagingId ? true : false;
  this.vForm.Packaging.$touch();
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.VisitBillOfLadingPackagingId) {
          this.form.PackagingList = this.form.PackagingList.map(Packaging => {
            if (Packaging.VisitBillOfLadingPackagingId == item.VisitBillOfLadingPackagingId) {
              return {
                ...Packaging,
                Status: 0,
              };
            } else {
              return Packaging;
            }
          });
        }else{
          this.form.PackagingList = this.form.PackagingList.filter(e => e.IdX != item.IdX);
        }
        this.vForm.PackagingList.$touch();
      }
    });
}

function onChangeCommodityId(event) {
  this.form.Packaging.CommodityId = event.target.value;
  if (this.form.Packaging.CommodityId) {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let CommodityData = this.CommodityList.find(item => item.CommodityId == this.form.Packaging.CommodityId);
    this.vForm.Packaging.HeadingName.$model = CommodityData?.[`HeadingName${_lang}`] ?? '';
    this.vForm.Packaging.HeadingId.$model = CommodityData?.HeadingId ?? '';
  }else{
    this.vForm.Packaging.HeadingId.$model = '';
  }
}

function ValidatePackagingContainer(PackagingId) {
  return PackagingId.toUpperCase() == process.env.VUE_APP_PACKAGING_ID_CONTAINER;
}

function Clear(All) {
  this.CleanInformation = true;
  this.form.Packaging = {
    Id: '',
    PackagingId: '',
    PackagingNameEn: '',
    PackagingNameEs: '',
    HeadingId: '',
    HeadingName: '',
    CommodityId: '',
    Serial: '',
    DescriptionOfGoods: '',
    Length: 0,
    Width: 0,
    Height: 0,
    Weight: 0, 
    Quantity: 0,
    Volumen: 0,
    FgPackaging: false,
  };
  this.FgExternPackaging = false;
  if (All) {
    this.form.PackagingList = [];
    this.PackagingSelectList = [];
  }
  setTimeout(() => {
    this.vForm.Packaging.$reset();
    this.CleanInformation = false;
  }, 10);
}

//computed
function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.form.PackagingList.filter(item => item.PackagingId && item.Status == 1).forEach((e) => {
    chart.push({
      value: e.PackagingId,
      label: e['PackagingName'+_lang],
    })
  })
  return chart;
}

function CommodityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CommodityList.map(function(e){
    chart.push({
      value: e.CommodityId,
      label: e['CommodityName'+_lang],
    })    
  })
  return chart;
}

function Meters() {
  return {
    SquareMeters: NumberFormater.setNum(this.form.Packaging.Length) * NumberFormater.setNum(this.form.Packaging.Width),
    Volumen: NumberFormater.setNum(this.form.Packaging.Length) * NumberFormater.setNum(this.form.Packaging.Width) * NumberFormater.setNum(this.form.Packaging.Height),
  }
}

function computedSerializationList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.form.PackagingList.filter(item => item.Status != 0);

  return List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      PackagingName: item['PackagingName'+_lang] ?? this.$t('label.BreakBulk'),
      Quantity: item.Quantity ?? 0,
      ComputedWeight: item.Weight ? NumberFormater.formatNumber(item.Weight, 2) : '0,00',
      ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
    }
  });
}

function serializationfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'width:15%;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'SquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'options', label: '', _style: 'width: 1%; min-width:90px; text-align:center;', sorter: false, filter: false },
  ];
}

export default {
  name: 'serialization-tab',
  components: {
      Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    CommodityList: {
      type: Array,
      default: () => [],
    },
    vForm: Object,
    showModal: Boolean,
    Tab: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getService,
    SubmitPackaging,
    EditPackaging,
    DeletePackaging,
    onChangeCommodityId,
    ValidatePackagingContainer,
    Clear,
  },
  computed:{
    PackagingOptions,
    CommodityOptions,
    Meters,
    computedSerializationList,
    serializationfields,
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.TabIndex = 0;
        this.Clear(true);
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.getService();
      }
    },
  }
};
</script>