<template>
  <CCol sm="12">
    <dataTableExtended
      class="align-center-row-datatable"
      :items="computedList"
      :fields="fields"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      sorter
    >
    </dataTableExtended>
  </CCol>
</template>
<script>

import General from '@/_mixins/general';

//data
function data() {
  return {
    
  };
}

//computeds
function computedList() {
  return [].map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:left', filter: false},
     { label: this.$t('label.yard'), key: 'TpDamageName',_style: 'width:20%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.bl'), key: 'TpDamageNameEn',_style: 'width:15%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.packaging'), key: 'TpDamageNameEn',_style: 'width:16%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.quantity'), key: 'TpDamageAbbrev',  _classes: 'text-uppercase text-center', _style: 'width:10%;', },
     { label: this.$t('label.location'), key: 'TransaLogin', _classes: 'text-uppercase text-center', _style:'width:12%' },
     { label: `${this.$t('label.Day')}S`, key: 'Status', _classes: 'text-center text-uppercase', _style:'width:10%' },
     { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:12%' },
  ];
}

export default{
  name: 'currentSituation',
  data,
  mixins: [General],
  props: {
    Bl: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    computedList,
    fields,
  },
}
</script>