<template>
    <div>
      <CModalExtended
        size="lg"
        :title="title"
        color="dark"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
        @update:show="$emit('close');"
      >
        <CRow>
          <CCol sm="12">
            <dataTableExtended
            :items="formatedItems"
            :fields="fields"
            column-filter
            :items-per-page="5"
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            hover
            small
            sorter
            pagination
          >
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
            <template #DocsRoute="{item}">
              <td class="text-center">
                <CButton
                  color="excel"
                  class="mr-1"
                  square
                  v-c-tooltip="{
                    content: $t('label.download')+' '+$t('label.document'),
                    placement: 'top-end',
                  }"
                  size="sm"
                  target="_blank"
                  :href="item.DocsRoute"
                  >
                  <CIcon name="cil-cloud-download" />
                </CButton>
              </td>
            </template>
            </dataTableExtended>
          </CCol>
        </CRow>
        <template #footer>
            <CButton outline color="wipe" class="d-flex align-items-center" @click="$emit('close')">
              <CIcon name="x"/>&nbsp;
              <span class="ml-1">{{$t('button.exit')}}</span>
            </CButton>
        </template>
      </CModalExtended>
    </div>  
  </template>
  
  <script>
  import UpperCase  from '@/_validations/uppercase-directive';
  import General from '@/_mixins/general';
  import { DateFormater } from '@/_helpers/funciones';
  
  function data () {
    return {
      documentItems: [],
      modalActive: false,
    }
  }
  
  function Refresh() {
    this.documentItems = [];
  }
  
  //computed
  function fields(){
    return [
      { key: 'Nro', label: '#',filter: false,_style: 'width:1%; text-align:left;'},
      { key: 'BlCargoDocumentDs',label: this.$t('label.document'), _style:'width:30%', _classes:'text-center center-cell' },
      { key: 'TransaLogin',label: this.$t('label.user'), _style:'width:30%', _classes:'text-center center-cell' },
      { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:20%;', _classes:'text-center center-cell'},
      { key: 'Status', label: this.$t('label.status'), _style:'width:20%;', _classes:'text-center center-cell'},
      { key: 'DocsRoute',label: '',_style: 'min-width:45px;', sorter: false, filter: false}
    ]
  }
  
  function formatedItems() {
    let Nro = 1;
     return this.documentItems.map((item) => {
       return {
         ...item,
         Nro: Nro++,
         BlCargoDocumentDs: item.BlCargoDocumentDs ?? '',
         TransaLogin: item.TransaLogin ?? 'N/A',
         _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate): 'N/A',
        DocsRoute: item.RouteBlCargoDocument
          ? `${
              this.$store.getters["connection/getBase"]
            }${item.RouteBlCargoDocument.replace("Public/", "", "i")}`
          :  "N/A"
       };
     });
   }
  
  export default {
    name: 'modal-bl-document',
    data,
    directives: UpperCase,
    props: { modal: Boolean, title: String, documentList: Array},
    mixins: [General],
    methods: {
      Refresh
    },
    computed: {
      fields,  
      formatedItems 
    },
    watch: {
      modal: function(val){
          this.modalActive = val;
          if (val) {
            this.documentItems = this.documentList && this.documentList.lenght != 0 ? this.documentList.filter(el =>  el.Status != "INACTIVO") : [];
          }else{
            this.Refresh();
          }
        }
    }
  }
  </script>
  <style lang="scss">
  .center-cell {
    text-align: center;
  }
  </style>