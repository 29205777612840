@@ -0,0 +1,2758 @@
<template>
  <form @submit.prevent="submitBody">
    <CModalExtended
      :title=title
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      size="xl"
      @update:show="resetForm"
    >
      <CustomTabs :active-tab="tabIndex" @update:activeTab="handleTab" class="text-table">
        
        <CustomTab :class="$v.form.dataBasic.$anyError ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.basicData')}}</span>
          </template>
          
          <DataBasicTab
            :form="form"
            :vForm="$v.form"
            :items = "items"
            :isEdit="isEdit"
            :showModal="modal"
            :Tab="tabIndex==0 && modal"
            :validCargoMaster = "validCargoMaster"
            :ContainerItem = "ContainerItem"
            @UpdateCommodityList="CommodityList=$event"
            @validCargoMaster = "(event)=>{validCargoMaster= event;}"
          />
        </CustomTab>

        <CustomTab :class="$v.form.PackagingList.$error ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.packaging')}}</span>
          </template>

          <PackagingTab
            :form="form"
            :CommodityList="CommodityList"
            :vForm="$v.form"
            :showModal="modal"
            :Tab="tabIndex==1 && modal"
          />
        </CustomTab>

        <CustomTab :class="$v.form.PackagingList.$error ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.Serialization')}}</span>
          </template>

          <SerializationTab
            :form="form"
            :CommodityList="CommodityList"
            :vForm="$v.form"
            :showModal="modal"
            :Tab="tabIndex==2 && modal"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.imdg')}}</span>
          </template>

          <ImdgTab
            :showModal="modal"
            :Tab="tabIndex==3 && modal"
            :form="form"
            :imdgItems = "imdgItems"
            :validCargoMaster = "validCargoMaster"
            @deleteImdg="(event) => deleteImdg(event)"
            @imdgUpdateItems="(event)=>{submitImdg(event)} "
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.seller')}}</span>
          </template>

          <SellerTab
            :showModal="modal"
            :Tab="tabIndex==4 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
            :validCargoMaster = "validCargoMaster"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.customBroker')}}</span>
          </template>

          <CustomsBrokerTab
            :showModal="modal"
            :Tab="tabIndex==5 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
            :validCargoMaster = "validCargoMaster"
          />
        </CustomTab>

        <CustomTab :class="$v.form.consignee.$anyError ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.consignee')}}</span>
          </template>

          <ConsigneeTab
            :showModal="modal"
            :Tab="tabIndex==6 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.notifyParty')}}</span>
          </template>
          
          <NotifyPartyTab
            :showModal="modal"
            :Tab="tabIndex==7 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
            :validCargoMaster = "validCargoMaster"
          />
        </CustomTab>

        <CustomTab :class="$v.form.DescriptionOfGoods.DescriptionOfGoods.$anyError ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.inventoryContains')}}</span>
          </template>

          <DiceContenerTab
            :showModal="modal"
            :Tab="tabIndex==8 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
            :validCargoMaster = "validCargoMaster"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.documentation')}}</span>
          </template>

          <DocumentationTab
            :showModal="modal"
            :Tab="tabIndex==9 && modal"
            :form="form"
            :Items = "documentItems"
            @documentItems = "(event)=> documentItems = event"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.status')}}</span>
          </template>

          <BlStatusTab
            :showModal="modal"
            :Tab="tabIndex==10 && modal"
            :form="form"
            :ContainerItem = "ContainerItem"
            :Items = "documentItems"
            @documentItems = "(event)=> documentItems = event" 
          />
        </CustomTab>
      </CustomTabs>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="isEdit ? statusConfirmation(ContainerItem.FgActBlCargoMaster,form.dataBasic.Status, submitBody) : submitBody()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>

    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import { mapState } from 'vuex';
import BlValidations from '@/_validations/bl-query/blValidations';
import { Money } from "v-money";
import PackagingTab from './tabs/packaging-tab';
import DataBasicTab from './tabs/dataBasic-tab';
import SerializationTab from './tabs/serialization-tab';
import ImdgTab from '@/pages/yard-management/container/bl/tabs/imdg-tab';
import SellerTab from '@/pages/yard-management/container/bl/tabs/seller-tab';
import CustomsBrokerTab from '@/pages/yard-management/container/bl/tabs/customsBroker-tab';
import ConsigneeTab from '@/pages/yard-management/container/bl/tabs/consignee-tab';
import NotifyPartyTab from '@/pages/yard-management/container/bl/tabs/notifyParty-tab';
import DiceContenerTab from '@/pages/yard-management/container/bl/tabs/diceContener-tab';
import DocumentationTab from '@/pages/yard-management/container/bl/tabs/documentation-tab';
import BlStatusTab from '@/pages/yard-management/container/bl/tabs/blStatus-tab';

let user = JSON.parse(localStorage.getItem('user'));

function data() {
  return {
    //Modal General
    modalActive: false,
    show: false,
    tabIndex: 0,
    showModal: false,
    Loading: false,
    isSubmit: false,
    headingJsonDB: [],
    CommodityList: [],
    
    //DataBasic
    masterId: process.env.VUE_APP_TP_BL_ID_MASTER,
    houseId: process.env.VUE_APP_TP_BL_ID_HOUSE,

    //Imdg
    imdgItemsDB: [],
    imdgItems: [],

    //Documentation
    documentItems: [],
    documentItemsDB: [],

    //Validations
    validCargoMaster: false,

    //Form
    form: {
      dataBasic:{
        BlCargoId: '',
        bl: '',
        TpBlId: '',
        BlCargoMasterId: '',
        PortActivityId: '',
        VisitIdOrItineraryId: '',
        Pol: '',
        Pod: '',
        TpCargoId: '',
        TpCargoClassifId: '',
        CommodityId: '',
        BookingNro: '',
        Weight: '',
        Procedence: '',
        Volumen: '',
        Quantity: '',
        Viscosity: '',
        Temperature: '',
        headingJson: [],
        customsRegime:'',
        ReceptionBpAct: '',
        Dua: '',
        Status: 1
      },
      Packaging: {
        Id: '',
        CommodityId: '',
        HeadingName: '',
        Serial: '',
        PackagingId: '',
        PackagingNameEn: '',
        PackagingNameEs: '',
        DescriptionOfGoods: '',
        Weight: 0, 
        Quantity: 0,
        Volumen: 0,
        Length: 0,
        Width: 0,
        Height: 0,
        FgPackaging: false,
      },
      PackagingList: [],
      serialization: {
        Id: '',
        Serial: '',
        PackagingId: '',
        Weight: 0, 
        Volumen: 0,
        Length: 0,
        Width: 0,
        Height: 0,
      },
      SerializationList: [],
      imdg:{
        Id: 0,
        BlCargoMasterImdgId:'',
        ImdgId: '',
        ImdgClassId: '',
        PackagingGroupId: '',
        UnNumberId: '',
        DescriptionOfGoodsDGS: '',
        Status: 1
      },
      seller:{
        SellerName: '',
        SellerAddress: '',
        SellerPrimaryPhone: '',
        SellerSecondaryPhone: '',
        SellerPrimaryEmail: '',
        SellerSecondaryEmail: ''
      },
      customBroke:{
        CustomBrokerId: '',
        CustomBrokerRif: '',
        CustomBrokerName: '',
        CustomBrokerAddress: '',
        CustomBrokerPrimaryPhone: '',
        CustomBrokerSecondaryPhone: '',
        CustomBrokerPrimaryEmail: '',
        CustomBrokerSecondaryEmail: '',
        consolidatorCheck: false,
      },
      consolidator:{
        ConsolidatorId: '',
        ConsolidatorRif: '',
        ConsolidatorName: '',
        ConsolidatorAddress: '',
        ConsolidatorPrimaryPhone: '',
        ConsolidatorSecondaryPhone: '',
        ConsolidatorPrimaryEmail: '',
        ConsolidatorSecondaryEmail: ''
      },
      consignee:{
        ConsigneeId: '',
        ConsigneeRif: '',
        ConsigneeAddress: '',
        ConsigneeName: '',
        ConsigneePrimaryPhone: '',
        ConsigneeSecondaryPhone: '',
        ConsigneePrimaryEmail: '',
        ConsigneeSecondaryEmail: '', 
      },
      notify:{
        NotifyToName: '',
        NotifyToAddress: '',
        NotifyToPrimaryPhone: '',
        NotifyToSecondaryPhone: '',
        NotifyToPrimaryEmail: '',
        NotifyToSecondaryEmail: ''
      },
      DescriptionOfGoods:{
        DescriptionOfGoods: ''
      },
      status:{
        Status: '',
        TransactionDate: '',
        Motivo: '',
      },
    },
  };
}

function handleTab(tab) {
  this.tabIndex = tab;
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.$store.state.yardManagement.loading = true;

    this.$v.form.dataBasic.$touch();
    this.$v.form.PackagingList.$touch();
    this.$v.form.seller.$touch();
    this.$v.form.customBroke.$touch();
    this.$v.form.consolidator.$touch();
    this.$v.form.consignee.$touch();
    this.$v.form.notify.$touch();
    this.$v.form.DescriptionOfGoods.$touch();
    
    if ((this.$v.form.dataBasic.$error) || (this.$v.form.consignee.$error) || this.$v.form.DescriptionOfGoods.$error || 
      (this.form.dataBasic.Weight == '') || (this.form.dataBasic.Weight =='0,00') || (this.form.dataBasic.Weight== 0) ) {
      this.isSubmit = false;
      this.$store.state.yardManagement.loading = false;
  
      if (this.$v.form.dataBasic.$error || this.form.dataBasic.Weight == 0 || this.form.dataBasic.Weight == '') {
        throw this.$t("label.errorDatabasicBl");
      }

      if (this.$v.form.consignee.$error) {
        throw this.$t("label.errorConsigneeBl");
      }

      if (this.$v.form.DescriptionOfGoods.$error) {
        throw this.$t('label.errorDescriptionOfGoodsBl');
      }
    }
  
    let headingJson = [];

    let TotalheadingJson = [...this.headingJsonDB, ...this.form.dataBasic.headingJson];
    TotalheadingJson.map(item => {
      if (!headingJson.some(heading => {return heading.HeadingId == item.HeadingId})) {
        headingJson.push(item);
      }
    });

    headingJson = headingJson.map(item =>{
      let ValidatePreviousHeading = this.headingJsonDB.find(e => {
        return e.HeadingId == item.HeadingId;
      })
      let ValidateCurrentHeading= this.form.dataBasic.headingJson.some(heading => {
        return heading.HeadingId == item.HeadingId;
      })
      return {
        BlCargoHeadingId: ValidatePreviousHeading ? ValidatePreviousHeading.BlCargoHeadingId : '',
        HeadingId: item.HeadingId,
        Status: ValidateCurrentHeading ? 1 : 0,
      }
    })

    this.imdgItemsDB.map((e) => {
      if ( this.imdgItems.filter(el => el.BlCargoMasterImdgId == e.BlCargoMasterImdgId).length == 0 )
      {
        e.Status = "INACTIVO";
      }
    })
    
    let ImdgJson = this.imdgItemsDB.map((e) =>{
      return {
        BlCargoMasterImdgId: e.BlCargoMasterImdgId ?? '',
        ImdgId: e.ImdgId ?? '',
        UnNumberId: e.UnNumberId ?? '',
        PackagingGroupId: e.PackagingGroupId ?? '',
        DescriptionOfGoodsDGS: e.DescriptionOfGoodsDGS != 'N/A' ? e.DescriptionOfGoodsDGS : '',
        Status: e.Status == "ACTIVO" ? 1 : 0,
      }
    }
    );

    this.imdgItems.map((e) =>{
        ImdgJson.push({
          BlCargoMasterImdgId: e.BlCargoMasterImdgId ?? '',
          ImdgId: e.ImdgId ?? '',
          UnNumberId: e.UnNumberId ?? '',
          PackagingGroupId: e.PackagingGroupId ?? '',
          DescriptionOfGoodsDGS: e.DescriptionOfGoodsDGS != 'N/A' ? e.DescriptionOfGoodsDGS : '',
          Status: 1,
        });
      }
    )

    this.documentItemsDB.map((e) => {
      if ( this.documentItems.filter(el => el.BlCargoDocumentId == e.BlCargoDocumentId).length == 0 )
      {
        e.Status = "INACTIVO";
      }
    })
    

    let DocumentJson = this.documentItemsDB.map((e) =>{
      return {
        BlCargoDocumentId: e.BlCargoDocumentId ?? '',
        PortActivictyDocumentCargoId: e.PortActivictyDocumentCargoId ?? '',
        BlCargoDocumentDs: e.BlCargoDocumentDs ?? '',
        RouteBlCargoDocument: e.RouteBlCargoDocument ?? '',
        Status: e.Status == "ACTIVO" ? 1 : 0,
      }
      }
    );
 
    this.documentItems.map((e) =>{
        DocumentJson.push({
          BlCargoDocumentId: e.BlCargoDocumentId ?? '',
          PortActivictyDocumentCargoId: e.PortActivictyDocumentCargoId ?? '',
          BlCargoDocumentDs: e.BlCargoDocumentDs ?? '',
          RouteBlCargoDocument: e.RouteBlCargoDocument ?? '',
          Status: 1,
        });
      }
    )

    let BlCargoJson = this.isEdit ? {
        BlCargoId: this.form.dataBasic.BlCargoId, 
        TpBlId: this.form.dataBasic.TpBlId, 
        CargoId: this.CargoId,
        BlCargoMasterId: this.form.dataBasic.BlCargoMasterId,
        BlNro: this.form.dataBasic.bl,
        BookingNro: this.form.dataBasic.BookingNro,
        Weigth: this.form.dataBasic.Weight,
        Procedence: this.form.dataBasic.Procedence,
        Volumen: this.form.dataBasic.Volumen,
        Quantity: this.form.dataBasic.Quantity,
        ReceptionBpAct: this.form.dataBasic.ReceptionBpAct,
        Dua: this.form.dataBasic.Dua,
        CustomOperationId: this.form.dataBasic.customsRegime,
        HeadingJson: headingJson,

        ImdgJson: ImdgJson,

        CustomBrokerId: this.form.customBroke.CustomBrokerId,
        ConsolidatorId: this.form.consolidator.ConsolidatorId,

        SellerName: this.form.seller.SellerName,
        SellerAddress: this.form.seller.SellerAddress,
        SellerPrimaryPhone: this.form.seller.SellerPrimaryPhone,
        SellerSecondaryPhone: this.form.seller.SellerSecondaryPhone,
        SellerPrimaryEmail: this.form.seller.SellerPrimaryEmail,
        SellerSecondaryEmail: this.form.seller.SellerSecondaryEmail,

        ConsigneeId: this.form.consignee.ConsigneeId,
        ConsigneeName: this.form.consignee.ConsigneeName,
        ConsigneeAddress: this.form.consignee.ConsigneeAddress,
        ConsigneePrimaryPhone: this.form.consignee.ConsigneePrimaryPhone,
        ConsigneeSecondaryPhone: this.form.consignee.ConsigneeSecondaryPhone,
        ConsigneePrimaryEmail: this.form.consignee.ConsigneePrimaryEmail,
        ConsigneeSecondaryEmail: this.form.consignee.ConsigneeSecondaryEmail,
       
        NotifyToName: this.form.notify.NotifyToName,
        NotifyToAddress: this.form.notify.NotifyToAddress,
        NotifyToPrimaryPhone: this.form.notify.NotifyToPrimaryPhone,
        NotifyToSecondaryPhone: this.form.notify.NotifyToSecondaryPhone,
        NotifyToPrimaryEmail: this.form.notify.NotifyToPrimaryEmail,
        NotifyToSecondaryEmail: this.form.notify.NotifyToSecondaryEmail,

        DescriptionOfGoods: this.form.DescriptionOfGoods.DescriptionOfGoods,
        DocumentJson: DocumentJson,

        Status: this.form.dataBasic.Status,
      }:{
        BlCargoId: this.form.dataBasic.BlCargoId, 
        TpBlId: this.form.dataBasic.TpBlId, 
        CargoId: this.CargoId,
        BlCargoMasterId: this.form.dataBasic.BlCargoMasterId,
        BlNro: this.form.dataBasic.bl,
        BookingNro: this.form.dataBasic.BookingNro,
        Weigth: this.form.dataBasic.Weight,
        Procedence: this.form.dataBasic.Procedence,
        Volumen: this.form.dataBasic.Volumen,
        Quantity: this.form.dataBasic.Quantity,
        ReceptionBpAct: this.form.dataBasic.ReceptionBpAct,
        Dua: this.form.dataBasic.Dua,
        CustomOperationId: this.form.dataBasic.customsRegime,
        HeadingJson: headingJson,
        
        ImdgJson: ImdgJson,

        CustomBrokerId: this.form.customBroke.CustomBrokerId,
        ConsolidatorId: this.form.consolidator.ConsolidatorId,

        SellerName: this.form.seller.SellerName,
        SellerAddress: this.form.seller.SellerAddress,
        SellerPrimaryPhone: this.form.seller.SellerPrimaryPhone,
        SellerSecondaryPhone: this.form.seller.SellerSecondaryPhone,
        SellerPrimaryEmail: this.form.seller.SellerPrimaryEmail,
        SellerSecondaryEmail: this.form.seller.SellerSecondaryEmail,

        ConsigneeId: this.form.consignee.ConsigneeId,
        ConsigneeName: this.form.consignee.ConsigneeName,
        ConsigneeAddress: this.form.consignee.ConsigneeAddress,
        ConsigneePrimaryPhone: this.form.consignee.ConsigneePrimaryPhone,
        ConsigneeSecondaryPhone: this.form.consignee.ConsigneeSecondaryPhone,
        ConsigneePrimaryEmail: this.form.consignee.ConsigneePrimaryEmail,
        ConsigneeSecondaryEmail: this.form.consignee.ConsigneeSecondaryEmail,
       
        NotifyToName: this.form.notify.NotifyToName,
        NotifyToAddress: this.form.notify.NotifyToAddress,
        NotifyToPrimaryPhone: this.form.notify.NotifyToPrimaryPhone,
        NotifyToSecondaryPhone: this.form.notify.NotifyToSecondaryPhone,
        NotifyToPrimaryEmail: this.form.notify.NotifyToPrimaryEmail,
        NotifyToSecondaryEmail: this.form.notify.NotifyToSecondaryEmail,

        DescriptionOfGoods: this.form.DescriptionOfGoods.DescriptionOfGoods,
        DocumentJson: DocumentJson,

        Status: this.form.dataBasic.Status,
      };
    
  
    // let metodo = 'POST';
    // let ruta = 'BlCargo-insert';

    this.$http.ejecutar(metodo, ruta, BlCargoJson, { root: 'BlCargoJson' })
      .then(response => {
        let ResponseJson = response.data.data[0].ResponseJson;
        if (ResponseJson.length == 0) {
          this.$emit('submited');
          this.modalActive = false;
          this.$notify({
            group: 'container',
            title: '¡Exito!',
            text: response.data.data[0].Response,
            type: "success"
          });
          this.$emit('Update-list');
          this.resetForm();
        }else{
          let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
          this.$store.state.yardManagement.loading = false;
          ResponseJson.map(item => {
            throw item[`ErrorMessage${_lang}`];
          })
        }
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
      });
  } catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

function getData(val) { 
  //dataBasic

  this.form.dataBasic.BlCargoId = val.BlCargoId ?? '';
  this.form.dataBasic.bl = val.BlNro ?? '';
  this.form.dataBasic.TpBlId = val.TpBlId ?? '';
  this.form.dataBasic.BlCargoMasterId = val.BlCargoMasterId ?? '';
  this.form.dataBasic.BookingNro = val.BookingNro ?? '';
  this.form.dataBasic.Weight = val.Weigth ?? '';
  this.form.dataBasic.Procedence = val.Procedence ?? '';
  this.form.dataBasic.Volumen = val.Volumen ?? '';
  this.form.dataBasic.Quantity = val.Quantity ?? '';
  this.form.dataBasic.Viscosity = val.Viscosity ?? '';
  this.form.dataBasic.Temperature = val.Temperature ?? '';
  this.form.dataBasic.TpBlId = val.TpBlId ?? '';
  this.form.dataBasic.BlCargoMasterId = val.BlCargoMasterId ?? '';
  this.form.dataBasic.PortActivityId = val.PortActivityId ?? '';
  this.form.dataBasic.VisitIdOrItineraryId = val.VisitId ?? (val.ItineraryId ?? '');
  this.form.dataBasic.Pol = val.LoadPortId ?? '';
  this.form.dataBasic.Pod = val.DischargePortId ?? '';
  this.form.dataBasic.TpCargoId = val.TpCargoId ?? '';
  this.form.dataBasic.TpCargoClassifId = val.TpCargoClassifId ?? '';
  this.headingJsonDB = val.BasicDataJson?.[0]?.HeadingJson && val.BasicDataJson[0]?.HeadingJson?.length != 0 ? val.BasicDataJson[0]?.HeadingJson : [];
  this.form.dataBasic.headingJson = val.BasicDataJson?.[0]?.HeadingJson && val.BasicDataJson[0]?.HeadingJson?.length != 0 ? val.BasicDataJson[0]?.HeadingJson : [];
  this.form.dataBasic.customsRegime = val.CustomOperationId ?? '';
  this.form.dataBasic.ReceptionBpAct = val.ReceptionBpAct ?? '';
  this.form.dataBasic.Dua = val.Dua ?? '';
  this.form.dataBasic.Status = val.FgActBlCargoMaster? 1:0;

  //detail
  this.form.PackagingList = val?.DetailJson ?? [];

  //IMDG
  this.imdgItemsDB = val.ImdgJson&&val.ImdgJson.length!=0 ? val.ImdgJson : [];
  this.imdgItems = val.ImdgJson&&val.ImdgJson.length!=0 ? val.ImdgJson : [];

  //seller
  this.form.seller.SellerName = val.SellerJson?.[0]?.SellerName ?? '';
  this.form.seller.SellerAddress = val.SellerJson?.[0]?.SellerAddress ?? '';
  this.form.seller.SellerPrimaryPhone = val.SellerJson?.[0]?.SellerPrimaryPhone ?? '';
  this.form.seller.SellerSecondaryPhone = val.SellerJson?.[0]?.SellerSecondaryPhone ?? '';
  this.form.seller.SellerPrimaryEmail = val.SellerJson?.[0]?.SellerPrimaryEmail ?? '';
  this.form.seller.SellerSecondaryEmail = val.SellerJson?.[0]?.SellerSecondaryEmail ?? '';

  //customBroke
  this.form.customBroke.CustomBrokerId = val.CustomBrokerJson?.[0]?.CustomBrokerId ?? '';
  this.form.customBroke.CustomBrokerRif = val.CustomBrokerJson?.[0]?.CustomBrokerRif ?? '';
  this.form.customBroke.CustomBrokerName =  val.CustomBrokerJson?.[0]?.CustomBrokerName ?? '';
  this.form.customBroke.CustomBrokerAddress = val.CustomBrokerJson?.[0]?.CustomBrokerAddress ?? '';
  this.form.customBroke.CustomBrokerPrimaryPhone = val.CustomBrokerJson?.[0]?.CustomBrokerPrimaryPhone ?? '';
  this.form.customBroke.CustomBrokerSecondaryPhone = val.CustomBrokerJson?.[0]?.CustomBrokerSecondaryPhone ?? '';
  this.form.customBroke.CustomBrokerPrimaryEmail = val.CustomBrokerJson?.[0]?.CustomBrokerPrimaryEmail ?? '';
  this.form.customBroke.CustomBrokerSecondaryEmail = val.CustomBrokerJson?.[0]?.CustomBrokerSecondaryEmail ?? '';
  
  //consolidator
  this.form.consolidator.ConsolidatorId = val.ConsolidatorJson?.[0]?.ConsolidatorId ?? '';
  this.form.consolidator.ConsolidatorRif = val.ConsolidatorJson?.[0]?.ConsolidatorRif ?? '';
  this.form.consolidator.ConsolidatorName = val.ConsolidatorJson?.[0]?.ConsolidatorName ?? '';
  this.form.consolidator.ConsolidatorAddress = val.ConsolidatorJson?.[0]?.ConsolidatorAddress ?? '';
  this.form.consolidator.ConsolidatorPrimaryPhone = val.ConsolidatorJson?.[0]?.ConsolidatorPrimaryPhone ?? '';
  this.form.consolidator.ConsolidatorSecondaryPhone = val.ConsolidatorJson?.[0]?.ConsolidatorSecondaryPhone ?? '';
  this.form.consolidator.ConsolidatorPrimaryEmail = val.ConsolidatorJson?.[0]?.ConsolidatorPrimaryEmail ?? '';
  this.form.consolidator.ConsolidatorSecondaryEmail = val.ConsolidatorJson?.[0]?.ConsolidatorSecondaryEmail ?? '';

  //consignee
  this.form.consignee.ConsigneeId = val.ConsigneeJson?.[0]?.ConsigneeId ?? '';
  this.form.consignee.ConsigneeRif = val.ConsigneeJson?.[0]?.ConsigneeRif ?? '';
  this.form.consignee.ConsigneeAddress = val.ConsigneeJson?.[0]?.ConsigneeAddress ?? '';
  this.form.consignee.ConsigneeName = val.ConsigneeJson?.[0]?.ConsigneeName ?? '';
  this.form.consignee.ConsigneePrimaryPhone = val.ConsigneeJson?.[0]?.ConsigneePrimaryPhone ?? '';
  this.form.consignee.ConsigneeSecondaryPhone = val.ConsigneeJson?.[0]?.ConsigneeSecondaryPhone ?? '';
  this.form.consignee.ConsigneePrimaryEmail = val.ConsigneeJson?.[0]?.ConsigneePrimaryEmail ?? '';
  this.form.consignee.ConsigneeSecondaryEmail = val.ConsigneeJson?.[0]?.ConsigneeSecondaryEmail ?? '';
 
  //notify
  this.form.notify.NotifyToName = val.NotifyToJson?.[0]?.NotifyToName ?? '';
  this.form.notify.NotifyToAddress = val.NotifyToJson?.[0]?.NotifyToAddress ?? '';
  this.form.notify.NotifyToPrimaryPhone = val.NotifyToJson?.[0]?.NotifyToPrimaryPhone ?? '';
  this.form.notify.NotifyToSecondaryPhone = val.NotifyToJson?.[0]?.NotifyToSecondaryPhone ?? '';
  this.form.notify.NotifyToPrimaryEmail = val.NotifyToJson?.[0]?.NotifyToPrimaryEmail ?? '';
  this.form.notify.NotifyToSecondaryEmail = val.NotifyToJson?.[0]?.NotifyToSecondaryEmail ?? '';
  
  this.documentItemsDB = val.DocumentJson && val.DocumentJson.length != 0 ? val.DocumentJson : [];
  this.documentItems = val.DocumentJson && val.DocumentJson.length != 0 ? val.DocumentJson : [];

  //DescriptionOfGoods
  this.form.DescriptionOfGoods.DescriptionOfGoods = val.DescriptionOfGoods;

  //validar check de consolidator
  if (this.form.consolidator.ConsolidatorId !='' ) {
    this.form.customBroke.consolidatorCheck = false;
  } else {
    this.form.customBroke.consolidatorCheck = true;
  }
 

  if(this.form.dataBasic.TpBlId === this.houseId.toUpperCase()){
    this.validCargoMaster = true;
  }else if (this.form.dataBasic.TpBlId === this.masterId.toUpperCase()){
    this.validCargoMaster = false;
  }


  this.$v.form.dataBasic.$touch();
  this.$v.form.seller.$touch();
  this.$v.form.customBroke.$touch();
  this.$v.form.consolidator.$touch();
  this.$v.form.consignee.$touch();
  this.$v.form.notify.$touch();
  this.$v.form.DescriptionOfGoods.$touch();
}

async function resetForm() {
  //validations
  this.form.customBroke.consolidatorCheck = false;
  this.validCargoMaster = false;

  //data array
  this.imdgItems = [];
  this.imdgItemsDB = [];
  this.documentItems = [];
  this.documentItemsDB = [];
  this.headingJsonDB = [];
  this.CommodityList = [];
  
  //tab
  this.tabIndex = 0;
  
  //await this.Refresh();
  setTimeout(() => {
    this.$v.$reset();
  }, 20)
  this.$emit('close');
}

//-----------------------  Tab Imdg   ------------------------

//-----------------------   Method    ------------------------

function submitImdg(item) {

  if (this.form.imdg.Id == 0) 
      this.imdgItems.push(item);
  else
    this.imdgItems.map((e) =>{
      if (e.IdX == this.form.imdg.Id) {
        {
          e.BlCargoMasterImdgId = item.BlCargoMasterImdgId, 
          e.ImdgCode = item.ImdgCode,
          e.ImdgClassCode = item.ImdgClassCode,
          e.ImdgClassId = item.ImdgClassCode,
          e.ImdgClassName = item.ImdgClassCode,
          e.ImdgId = item.ImdgId,
          e.ImdgName = item.ImdgName,
          e.UnNumberId = item.UnNumberId,
          e.UnNumber = item.UnNumber,
          e.PackagingGroupId = item.PackagingGroupId,
          e.PackagingGroupCode = item.PackagingGroupCode,
          e.DescriptionOfGoodsDGS = item.DescriptionOfGoodsDGS,
          e.Status = item.Status
        }
      }
    });
  
}

function deleteImdg(item) {

  this.$swal
      .fire(this.alertProperties({
          text: `${this.$t('label.deleteQuestion')}?`,
      }))
    .then((result) => {
    if (result.isConfirmed) {

      this.imdgItems = this.imdgItems.filter(e => e.IdX != item.IdX);
    }
  });
}

export default {
  name: 'modal-bl',
  components: {
    CustomTabs,
    CustomTab,
    PackagingTab,
    DataBasicTab,
    SerializationTab,
    ImdgTab,
    SellerTab,
    CustomsBrokerTab,
    ConsigneeTab,
    NotifyPartyTab,
    DiceContenerTab,
    DocumentationTab,
    BlStatusTab,
    Money,
   },
  props: { modal: Boolean, isEdit: Boolean, ContainerItem: Object, items: Array, title: String },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations(){ return BlValidations(this.form.customBroke.consolidatorCheck,this.validCargoMaster)},
  directives: UpperCase,
  methods: {
    submitBody,
    getData,
    resetForm,
    handleTab,
    deleteImdg,
    submitImdg
  },
  computed: {
    ...mapState({
      dataContainer: state => state.yardManagement.dataContainer,
      BlCargoMasterId: state => state.yardManagement.BlCargoMasterId,
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      branch: (state) => state.auth.branch,
    })

  },
  watch: {
    modal: async function (val) {
      this.$v.$reset();
      this.modalActive = val;
      if(val){
        if (this.isEdit) {
           await this.getData(this.ContainerItem);
          //llenado de data imdg lista y documentacion lista
          this.imdgItems = this.ContainerItem.ImdgJson && this.ContainerItem.ImdgJson.length != 0 ? this.ContainerItem.ImdgJson.filter(el =>  el.Status != 'INACTIVO') : [];
          this.documentItems = this.ContainerItem.DocumentJson && this.ContainerItem.DocumentJson.length != 0 ? this.ContainerItem.DocumentJson.filter(el =>  el.Status != 'INACTIVO') : [];
          this.form.dataBasic.headingJson = this.ContainerItem.BasicDataJson?.[0]?.HeadingJson && this.ContainerItem.BasicDataJson[0]?.HeadingJson?.length != 0 ? this.ContainerItem.BasicDataJson[0]?.HeadingJson.filter(el =>  el.Status != 'INACTIVO') : [];
        }
      
      }
    },
  },
};
</script>
<style lang="scss">

.text-table .custom-tab-content {
  background: #fff;
  border: 0;
  outline: none;
  border-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

</style>