<template>
  <div class="yard-body">
    <div class="wrapper d-flex align-items-stretch">
      <nav id="sidebar-Detail-Containers">
        <div class="list-menu">
          <CRow>
            <CCol sm="12">
              <img :src="imageSrc(Bl?.TpCargoRoute)" class="img bg-wrap text-center" @error="setAltImg"/>
            </CCol>
          </CRow>
        </div>
        <div class="p-3">
          <ul class="list-unstyled components mb-5">
            <li>
              <div class="list-menu">
                <CRow>
                  <CCol sm="12">
                    <h5 class="border-title d-flex align-items-center justify-content-center py-0">
                      <b><label class="mb-0">{{ `${$t('label.bl')}:  ${getData(Bl?.BlNro)}`}}</label></b>                                                   
                    </h5>
                    <h5 class="border-title d-flex align-items-center justify-content-center py-0">
                      <b class="mr-2"><label class="mb-0">{{ `${$t('label.activity')}: ${getData($i18n?.locale=='en' ? Bl?.PortActivityNameEn : Bl?.PortActivityNameEs) } `}}</label></b> 
                      <CIcon size='lg' name="checkListCircleGreen"/>
                    </h5>
                    <h5 class="border-title d-flex align-items-center justify-content-center py-0">
                      <b class="mr-2"><label class="mb-0">{{ `${$t('label.type')}: ${getData(Bl?.TpBlName) }`}}</label></b> 
                    </h5>
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{`${$t('label.client')}`}}  
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ `${getData(Bl.ClientName)}` }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{`${$t('label.visitItineraty')}`}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ Bl.VesselName ? `${getData(Bl.VesselName)}` : ''}}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.voyage')}}  
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getData(Bl?.Voyage) }}
                  </CCol>
                </CRow>
              </div>
            </li> 
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6" class="d-flex align-items-center">
                    {{`${getData(Bl?.LabelArrival)} / ${getData(Bl?.LabelDeparture)}`}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ `${getDate(Bl?.Arrival)} - ${getDate(Bl?.Departure)}` }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.DaysInPort')}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ !Number.isNaN(Bl?.DaysInPort) ? Bl?.DaysInPort : 0 }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.FirstEvent')}}
                  </CCol>
                  <CCol sm="6" class="pl-0 d-flex align-items-center">
                    {{ getData($i18n.locale=='en' ? Bl?.FirstEventNameEn : Bl?.FirstEventNameEs) }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.FirstEventDate')}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getDate(Bl?.FirstEventDate) }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.LastEvent')}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getData($i18n.locale=='en' ? Bl?.LastEventNameEn : Bl?.LastEventNameEs) }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{`${$t('label.LastEventDate')}`}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getDate(Bl?.LastEventDate) }}
                  </CCol>
                </CRow>
              </div>
            </li>
          </ul>
        </div>
      </nav>
            
      <!-- Page Content  -->
        
      <div id="content">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light-blue"
          :class="{'no-margin': null}"
        >
          <div class="container-fluid">
            <button type="button"  id="CollapseSidebar" class="btn bg-none text-withe" @click="checkSideBarToggle()">
              <CIcon  class="text-white" :name="checkIconToggle"/>
              <span class="sr-only">{{checkTextToggle}}</span>
            </button>
            <div class="collapse navbar-collapse">
              <ul class="nav navbar-nav ml-auto w-100 justify-content-start">
                <li class="border-title align-items-center justify-content-center mx-1 my-1" :style="`background: ${Bl?.YardCargoBlStatusColor ?? ''}`">
                  <label >{{` ${$t('label.condition')} : ${getData($i18n?.locale=='en' ? Bl?.YardCargoBlStatusEn : Bl?.YardCargoBlStatusEs)}`}}</label>
                </li>

                <li class="border-title align-items-center justify-content-center mx-1 my-1" :style="`background: ${Bl?.BlStatusColor ?? ''}`">
                  <label >{{` ${$t('label.status')} : ${getData($i18n?.locale=='en' ? Bl?.BlStatusEn : Bl?.BlStatusEs)}`}}</label>
                </li>

                <li class="border-title align-items-center justify-content-center mx-1 my-1" >
                  <label >{{` ${$t('label.quantity')} : ${FormatNumber(Bl?.Quantity, 2)}`}}</label>
                </li>

                <li class="border-title align-items-center justify-content-center mx-1 my-1" >
                  <label >{{` ${$t('label.weight')} : ${FormatNumber(Bl?.Weigth, 2)} (KGM)`}}</label>
                </li>

                <li class="border-title align-items-center justify-content-center mx-1 my-1" >
                  <label >{{` ${$t('label.volume')} : ${FormatNumber(Bl?.Volumen, 2)} (M³)`}}</label>
                </li>
                
              </ul>
            </div>
          </div>
        </nav>
          <!-- Contenido Interno -->
        <div class="container-fluid contenido-especial pt-3" >
          <CustomTabs>
            <CustomTab>
              <template #title>
                <span>{{$t('label.CurrentSituation')}}</span>
              </template>
              <CurrentSituation
              
              />
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.basicData')}}</span>
              </template>
              <BasicData 
                :Bl="Bl"
              />
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{ $t('label.house') }}</span>
              </template>
              
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.packaging')}}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.events')}}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.imdg')}}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.seller')}}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.customBroker')}}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.importer') }} / {{$t('label.exporter') }}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.notifyParty')}}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.DescriptionOfGoods')}}</span>
              </template>
            
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.transactionHistory')}}</span>
              </template>
          
            </CustomTab>
          </CustomTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import General from '@/_mixins/general';
import { mapState } from 'vuex';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import CurrentSituation from './taps/currentSituation/index';
import BasicData from './taps/basicData/index';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    collapsed: false,
    collapseDuration: 0,
    toggled: false
  };
}

//mounted
function mounted(){
  document.getElementById("CollapseSidebar").onclick = function() {
    document.getElementById("sidebar-Detail-Containers").classList.toggle('active');
  }
}

//methods
function imageSrc(Src){
  return `${this.$store.getters["connection/getBase"]}${Src}`;
}

function setAltImg(event){
  event.target.src = '/img/errorimage.jpg';
}

function getData(Data) {
  return Data ? Data : '';
}

function getDate(date) {
  if (date) {
    return DateFormater.formatDateTimeWithSlash(date);
  } else {
    return 'N/A';
  }
}

function FormatNumber(item, decimal) {
  return  NumberFormater.formatNumber(item, decimal)
}

function checkSideBarToggle(){
  this.collapsed = !this.collapsed;
}

//computeds
function checkIconToggle() {
  if (this.collapsed) {
    return "cil-chevron-right-alt";
  } else {
    return "cil-chevron-left-alt";
  }
}

function checkTextToggle() {
  if (this.collapsed) {
    return this.$t("label.close");
  } else {
    return this.$t("label.watch");
  }
}

function computedList() {
  return [].map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:left', filter: false},
     { label: this.$t('label.yard'), key: 'TpDamageName',_style: 'width:20%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.bl'), key: 'TpDamageNameEn',_style: 'width:15%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.packaging'), key: 'TpDamageNameEn',_style: 'width:16%;',_classes: 'text-uppercase text-center'},
     { label: this.$t('label.quantity'), key: 'TpDamageAbbrev',  _classes: 'text-uppercase text-center', _style: 'width:10%;', },
     { label: this.$t('label.location'), key: 'TransaLogin', _classes: 'text-uppercase text-center', _style:'width:12%' },
     { label: `${this.$t('label.Day')}S`, key: 'Status', _classes: 'text-center text-uppercase', _style:'width:10%' },
     { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:12%' },
  ];
}

export default{
  name: 'bl-info',
  data,
  mounted: mounted,
  components: {
    CustomTabs,
    CustomTab,
    CurrentSituation,
    BasicData,
  },
  mixins: [General],
  props: {
    Bl: {
      type: Object,
      Required: true,
      default: undefined,
    },
  },
  methods: {
    imageSrc,
    setAltImg,
    getData,
    getDate,
    FormatNumber,
    checkSideBarToggle,
  },
  computed: {
    checkIconToggle,
    checkTextToggle,
    computedList,
    fields,
    ...mapState({
      options: state => state.containerSearch.containerOptions,
    })
  },
  watch:{

  }
}
</script>
<style lang="scss">
  .custom-nav li > .custom-tab-link{
    .dropdown{
      width:100%;
      padding: 0 20px;
      .dropdown-toggle {
        color:white;
        opacity: 1;
        padding-left: 0;
        padding-top: 0;
      }
      .dropdown-toggle::after {
        margin-left: 0.255em;
        margin-right: 1.0em;
      }
    }
  }
  .custom-nav li.active > .custom-tab-link {
    .dropdown{
        width:100%;
        padding: 0 20px;
      .dropdown-toggle {
        color: #000;
        opacity: 1;
      }
      .dropdown-toggle::after {
        margin-left: 0.255em;
        margin-right: 1.0em;
      }
    }
  }
</style>
<style scoped>
.icon{
  background-color: null; 
  border: 0px;
}
.Labels {
  font-size: 10px;
}
.border-title{
  background-color: #1a3760;
  color: white;
  display: block;
  height: calc(1.5em + 0.75rem + 1px);
  padding: 0.375rem 0.75rem;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  border-color: #d8dbe0;
  border-radius: 0.50rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>